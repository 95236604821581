import Flickity from "flickity";
import AbstractComponent from "../abstract-component";
import { hasClass } from "../../utils/css-utils";

export default class MediaGallery extends AbstractComponent {
	constructor(...args) {
		super(...args);
		this.classname = "c-master-media-gallery";
		this.isMoving = false;
		this.init();
	}
	init() {
		this.initDomElements();
		this.initSlider();
		this.addEventListeners();
	}
	initDomElements() {
		this.$container = this.$el.querySelector(
			"." + this.classname + "__content"
		);
		this.$images = this.$el.querySelectorAll(
			"." + this.classname + "__content__item"
		);
		this.$video_links = this.$el.querySelectorAll(
			"." + this.classname + "__content__item__play-icon"
		);
	}
	addEventListeners() {
		this.slider.on("staticClick", this.playVideo.bind(this));
	}
	getSliderConfiguration() {
		const has_enough = this.$images.length > 1;
		const sliderConfiguration = {
			cellAlign: "center",
			contain: true,
			groupCells: true,
			wrapAround: true,
			pageDots: has_enough,
			prevNextButtons: has_enough,
			arrowShape:
				"M55.8,56.7c0.3,0,0.6-0.2,0.9-0.3l7.9-7.7c0.3-0.2,0.3-0.6,0.3-0.9c0-0.3-0.2-0.6-0.3-0.9l-7.7-7.7 c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0.2-0.8,0.5-0.9,0.9s0,0.9,0.5,1.3l5.7,5.5H31.4c-0.8,0-1.3,0.6-1.3,1.3s0.5,1.3,1.3,1.3h29.1 l-5.7,5.7c-0.5,0.5-0.5,1.3,0,1.7C55,56.5,55.3,56.7,55.8,56.7",
		};
		return sliderConfiguration;
	}
	initSlider() {
		if (!this.slider) {
			this.slider = new Flickity(
				this.$container,
				this.getSliderConfiguration()
			);
			this.slider.on("dragStart", () => (this.isMoving = true));
			this.slider.on("dragEnd", () => (this.isMoving = false));
		}
	}
	destroySlider() {
		if (this.slider) {
			this.slider.destroy();
			this.slider = null;
		}
	}
	playVideo(e) {
		if (hasClass(e.target, this.classname + "__content__item__play-icon")) {
			this.app.emit("play-video", e.target.getAttribute("data-video-id"));
		}
	}
	destroy() {}
}
