import { addEventListener } from "../../utils/event-utils";
import { MANAGE_CENSUS_EVENT } from "./census";
import gsap from "gsap";
import Lottie from "lottie-web";

export default class Illustrations {
	constructor(census) {
		this.census = census;
		this.actives = [];
		this.inactives = [];
		this.init();
	}
	init() {
		this.initDomElements();
		this.playLotties();
		this.addEventListeners();
	}
	initDomElements() {
		this.$clouds = this.census.$el.querySelector(".js-clouds");
		this.$birds = this.census.$el.querySelector(".js-birds");
		this.$nests = this.census.$el.querySelector(".js-nests");
		this.$nests_rustic = this.census.$el.querySelector(".js-nests-rustic");
		this.illustrations = {
			birds: this.$birds,
			nests: this.$nests,
			nests_rustic: this.$nests_rustic,
			clouds: this.$clouds,
		};
		this.illustrationsArray = [this.$birds, this.$nests,this.$nests_rustic, this.$clouds];
	}
	addEventListeners() {
		this.census.on(
			MANAGE_CENSUS_EVENT.NEXT_ILLUSTRATIONS,
			this.showIllustrations.bind(this)
		);
	}
	playLotties() {
		this.illustrationsArray.forEach((illustration) => {
			const elements = illustration.querySelectorAll(".js-json");

			elements.forEach((el) => {
				const json = el.querySelector("script");
				Lottie.loadAnimation({
					container: el,
					renderer: "canvas",
					loop: true,
					autoplay: true,
					viewBoxOnly:true,
					rendererSettings: {
						filterSize: {
							width: "200%",
							height: "200%",
							x: "-50%",
							y: "-50%",
						},
					},
					animationData: JSON.parse(json.textContent),
				});
			});
		});
	}
	showIllustrations(keys) {
		const actives = [];
		for (const key in this.illustrations) {
			if (keys.includes(key)) {
				actives.push(this.illustrations[key]);
			}
		}
		const toHide = _.difference(this.actives, actives);
		const toShow = _.difference(actives, this.actives);
		if (toHide.length) {
			gsap.fromTo(
				toHide,
				{ opacity: 1, y: 0 },
				{ opacity: 0, y: 20, duration: 0.5 }
			);
		}
		if (toShow.length) {
			gsap.fromTo(
				toShow,
				{ opacity: 0, y: 20 },
				{ opacity: 1, y: 0, duration: 0.5, delay: 0.5 }
			);
		}
		this.actives = actives;
	}
}
