import AbstractComponent from "./abstract-component";
import Lottie from "lottie-web";
export default class DetailsHirondelle extends AbstractComponent {
	constructor(el) {
		super(el);
		this.classname = "c-details-hirondelle";
		this.init();
	}
	init() {
		this.initDomElements();

		if (this.$el) {
			this.$players.forEach(player => {
			this.initLottie(player);
			});

		}
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
		this.$players = this.$el.querySelectorAll(".js-" + this.classname + "__json");
	}
	initLottie(player) {
		const json = player.querySelector("script");
		const lottie = Lottie.loadAnimation({
			container: player,
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: JSON.parse(json.textContent),
		});
		lottie.play();
	}
	destroy() {}
}
