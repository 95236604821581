import AbstractComponent from "./abstract-component";

export default class SiteFooter extends AbstractComponent {
	constructor(el) {
		super(el);
		this.classname = "c-site-footer";
		this.height = 0;
		this.init();
	}
	init() {
		this.initDomElements();
		if (this.$el) {
			this.addEventListeners();
		}
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
		if (this.$el) {
			this.setHeight();
		} else {
			this.$el = null;
		}
	}
	addEventListeners() {
		this.app.on("resize", this.setHeight.bind(this));
	}
	setHeight() {
		this.height = this.$el.getBoundingClientRect().height;
	}
	destroy() {}
}
