import EventEmitter from "eventemitter3";
import { addClass, css, removeClass } from "../../utils/css-utils";
import QuizManagers from './_map'
import _ from "lodash"


export default class Quiz extends EventEmitter {
	constructor({ device, controller }) {
		super();
		this.classname = "c-quiz-container";
		this.questions = [];
		this.current_index = 0;
		this.device = device;
		this.controller = controller;
		this.quiz_managers = QuizManagers
		this.init();
	}
	init() {
		this.initDomElements();

		this.addEventListeners();
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
		this.$quiz_loader = this.$el.querySelectorAll(".js-loader");
	}
	initManagers() {
		const quiz_manager = this.quiz_managers[this.device]
		this.question_manager = new quiz_manager.default({
			el: this.$el.querySelector(".js-question"),
			question: this.getCurrentQuestion(),
			quiz: this,
		});

		this.result_manager = new ResultManager({
			element: this.$el.querySelector(".js-results"),
			quiz: this,
		});
	}
	addEventListeners() {
		this.controller.on(MANAGE_QUIZ_EVENT.QUIZ_START, this.initQuiz.bind(this));
		this.controller.on(
			MANAGE_QUESTION_EVENT.LAST_QUESTION,
			this.endQuiz.bind(this)
		);
	}
	initQuiz(data) {
		this.questions = data.questions;
		css(this.$quiz_loader, { display: "none" });
		this.initManagers();
		this.question_manager.showQuiz();
	}
	endQuiz() {
		this.getResult();
		this.controller.emit(MANAGE_QUIZ_EVENT.QUIZ_END, this.result);
	}
	getResult() {
		this.result = 0;
		this.questions.forEach((question) => {
			const good_answers = [];
			question.answers.forEach((answer,i)=>{
				if (answer.is_right) {
					good_answers.push(i)
				}
			})
			if (_.isEqual(good_answers.sort(), question.answered.sort())) {
				this.result++;
			}
		});
	}
	showResult() {
		css(this.$question, { display: "none" });
	}
	getCurrentQuestion() {
		return this.questions[this.current_index];
	}
}

class ResultManager {
	constructor({ element, quiz }) {
		this.$el = element;
		this.quiz = quiz;
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$title = this.$el.querySelector(".js-results__header__title");
	}
	showResult(result) {
		this.$title.innerText = `Vous avez obtenu un score de ${result} bonne${result > 1 ? "s":""} réponse${result > 1 ? "s":""} !`;
		css(this.$el, { display: "flex" });
	}
	addEventListeners() {
		this.quiz.controller.on(
			MANAGE_QUIZ_EVENT.QUIZ_END,
			this.showResult.bind(this)
		);
	}
}
export const MANAGE_QUIZ_EVENT = {
	QUIZ_START: "quiz-start",
	QUIZ_END: "quiz-end",
};
export const MANAGE_QUESTION_EVENT = {
	VALIDATE_ANSWER: "validate-answer",
	NEXT_QUESTION: "next-question",
	INIT_QUESTION: "init-question",
	LAST_QUESTION: "last-question",
};
