import EventEmitter from "eventemitter3";
import { addEventListener } from "../../utils/event-utils";
import { MANAGE_CENSUS_EVENT } from "./census";

export default class Counter extends EventEmitter {
	constructor($el,census) {
		super();
		this.$el = $el;
		this.census = census;
		this.current = 0;
		this.init();
	}
	init() {
		this.initDomElements();
		this.setValue();
		this.addEventListeners();
	}
	initDomElements() {
		this.$plus = this.$el.querySelector(".plus");
		this.$minus = this.$el.querySelector(".minus");
		this.$result = this.$el.querySelector(".result");
	}
	addEventListeners() {
		addEventListener(this.$plus, "click", this.increment.bind(this));
		addEventListener(this.$minus, "click", this.decrement.bind(this));
		addEventListener(this.$result, "blur", this.setValueFromInput.bind(this));
	}
	increment() {
		this.current++;
		this.setValue();
	}
	decrement() {
		if (this.current > 0) {
			this.current--;

		}
		this.setValue();
	}
	setValue() {
		this.$result.value = `${this.current < 10 ? "0" : ""}${this.current}`;
		this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE,this.current)
	}
	setValueFromInput() {
		if (this.$result.value >= 0) {
			this.current = this.$result.value;
		}else{
			this.current = 0;
		}
		this.$result.value = `${this.current > 0 && this.current < 10 && !this.current.includes('0') ? "0" : ""}${this.current}`;
		this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE,this.current)
	}
}
