import AbstractComponent from "./abstract-component";

export default class SiteMenu extends AbstractComponent {
	constructor(el) {
		super(el);
		this.classname = "c-site-menu";
		this.isOpen = false;
		this.openedSubMenu = null;
		this.init();
	}
	init() {
		this.initDomElements();
		this.handleScroll();
		this.addEventListeners();
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
		this.$elContainer = document.querySelector(
			".js-" + this.classname + "__container"
		);
		this.$togglerButton = document.querySelector(
			".js-" + this.classname + "__burger"
		);
		this.$backButtons = document.querySelectorAll(
			".js-" + this.classname + "__nav-list__sub__back-button"
		);
		this.$links = document.querySelectorAll(
			".js-" + this.classname + "__nav-list__item a.sub-menu"
		);
		this.setheight();
	}
	addEventListeners() {
		this.app.on("scroll", this.handleScroll.bind(this));
		this.app.on("resize", this.handleResize.bind(this));
		this.$togglerButton.addEventListener("click", this.toggleMenu.bind(this));
		if (window.matchMedia("screen and (max-width: 1023px)").matches) {
			this.$links.forEach((link) => {
				const subButton = link.querySelector(".js-open-sub");
				subButton.addEventListener("click", this.openSubMenu.bind(this));
			});
			this.$backButtons.forEach((button) => {
				button.addEventListener("click", this.closeSubMenu.bind(this));
			});
		}
	}
	toggleMenu() {
		this.$el.classList.toggle("open");
		this.isOpen = !this.isOpen;
		if (this.isOpen) {
			this.app.emit("disable-scroll");
		} else {
			this.app.emit("enable-scroll");
		}
		if (this.openedSubMenu) {
			this.closeSubMenu();
		}
	}
	openSubMenu(e) {
		if (e.target.parentNode.href != "" && e.target.parentNode.href != "#") {
			e.stopPropagation();
			e.preventDefault();
		}
		this.openedSubMenu = e.target.closest(
			".js-" + this.classname + "__nav-list__item"
		);
		this.openedSubMenu.classList.add("open");
		this.$el.classList.add("sub-open");
	}
	closeSubMenu() {
		this.openedSubMenu.classList.remove("open");
		this.$el.classList.remove("sub-open");
		this.openedSubMenu = null;
	}
	handleResize() {
		this.setheight();
	}
	setheight() {
		this.height = this.$elContainer.getBoundingClientRect().height;
	}
	handleScroll() {
		if (window.scrollY > 20) {
			this.$elContainer.classList.add(
				this.classname + "__container" + "--fixed"
			);
		} else {
			this.$elContainer.classList.remove(
				this.classname + "__container" + "--fixed"
			);
		}
	}
	destroy() {}
}
