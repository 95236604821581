import { getList, hasProperty, isArrayOrList } from './js-utils';
import { upperFirst } from 'lodash';


/**
* Adds the passed class to the passed element/s.
*/
export function addClass( el, className ) {
    el = getList( el );

    var classNames = className.split( ' ' );

    for ( var a = 0; a < classNames.length; a++ ) {
        className = classNames[a];
        if( className ) {
            for ( var i = 0; i < el.length; i++ ) {
                var item = el[i];
                if ( !hasClass( item, className ) ) {
                    if ( item.classList ) {
                        item.classList.add( className );
                    } else {
                        item.className += ' ' + className;
                    }
                }
            }
        }
    }

    return el;
}


/**
* Removes the passed class to the passed element/s.
* @param {String} `className` can be multiple classnames separated by whitespace
*/
export function removeClass( el, className ) {
    el = getList( el );

    var classNames = className.split( ' ' );

    for ( var a = 0; a < classNames.length; a++ ) {
        className = classNames[a];
        for ( var i = 0; i < el.length; i++ ) {
            var item = el[i];
            if ( hasClass( item, className ) ) {
                if ( item.classList ) {
                    item.classList.remove( className );
                } else {
                    item.className = item.className.replace( new RegExp( '(^|\\b)' + className.split( ' ' ).join( '|' ) + '(\\b|$)', 'gi' ), ' ' );
                }
            }
        }
    }

    return el;
}


/**
* Checks if the passed class is applied to the passed element/s.
*/
export function hasClass( el, className ) {
    el = getList( el );

    for ( var i = 0; i < el.length; i++ ) {
        var item = el[i];
        if ( item.classList && item.classList.contains( className ) ) {
            return true;
        } else if ( ( ' ' + item.className + ' ' ).indexOf( ' ' + className + ' ' ) > -1 ) {
            return true;
        }
    }

    return false;
}


/**
* Set's the CSS properties for the passed item/s.
* @param {NodeList|HTMLElement} items
* @param {Object} props css properties and values.
*/
export function css( items, props ) {
    items = getList( items );

    var styles = window.getComputedStyle( document.documentElement, '' );
    var prefix = ( Array.prototype.slice .call( styles ).join( '' ).match( /-(moz|webkit|ms)-/ ) || ( styles.OLink === '' && ['', 'o'] ) )[1];

    var key;
    for ( key in props ) {
        if ( hasProperty( props, key ) ) {
            if ( key !== null ) {
                for ( var i = 0; i < items.length; i++ ) {
                    var item = items[i];
                    var prefixedKey = prefix + upperFirst( key );
                    item.style[prefixedKey] = props[key];
                    item.style[key] = props[key];
                }
            }
        }
    }

    return items;
}


/**
* Set's the CSS properties for the passed item/s.
* @param {NodeList|HTMLElement} items
* @param {Object} props css properties and values.
*/
export function getCss( item, key ) {
    if ( isArrayOrList( item ) && ( item.length > 0 ) ) {
        item = item[0];
    }

    const value = window.getComputedStyle( item ).getPropertyValue( key );
    return ( value.length > 0 ) ? value : null ;
}
