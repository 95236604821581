import { getList } from './js-utils';


/**
* Adds the passed event listener for the passed event to the passed element/s.
*/
export function addEventListener( el, ...args ) {
    el = getList( el );

    for ( var i = 0; i < el.length; i++ ) {
        el[i].addEventListener( ...args );
    }

    return el;
}


/**
* Adds the passed event listener for the passed event to the passed element/s.
*/
export function removeEventListener( el, ...args ) {
    el = getList( el );

    for ( var i = 0; i < el.length; i++ ) {
        el[i].removeEventListener( ...args );
    }

    return el;
}


/**
* Prevents the default behavior for the passed event.
*/
export function preventDefault( event ) {
    if ( event.preventDefault ) {
        event.preventDefault();
    } else {
        event.returnValue = false;
    }
}