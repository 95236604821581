import AbstractComponent from "../abstract-component";

export default class TwoColumns extends AbstractComponent {
	constructor(...args) {
		super(...args);
		this.classname = "c-master-two-columns";
		this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$video_link = this.$el.querySelector(
			"." + this.classname + "__play-icon"
		);
	}
	addEventListeners() {
		if (this.$video_link) {
			this.$video_link.addEventListener("click", this.playVideo.bind(this));
		}
	}
	playVideo() {
		this.app.emit("play-video", this.$video_link.getAttribute("data-video-id"));
	}
	destroy() {}
}
