import AbstractComponent from "./abstract-component";
import gsap from "gsap";

export default class PageLoader extends AbstractComponent {
	constructor(el) {
		super(el);
		this.classname = "c-page-loader";
		this.timeline = null;
		this.shouldRun = true;
		this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
		this.$lines = document.querySelectorAll(".js-" + this.classname + "__line");
		this.$pageLinks = document.querySelectorAll("a");
		this.app.emit("disable-scroll");
	}
	addEventListeners() {
		this.$pageLinks.forEach((link) => {
			link.addEventListener("click", this.handlePageChange.bind(this));
		});
		window.addEventListener("pageshow", this.handlePageShow.bind(this));
	}
	runOpenAnimation() {
		this.timeline = gsap.timeline();
		this.timeline.to(this.$lines, {
			x: "105%",
			scaleY:1.01,
			ease: "power1.inOut",
			stagger: {
				from: "start",
				amount: 0.5,
			},
		});
		this.timeline.eventCallback("onComplete", this.removeLoader.bind(this));
	}
	runCloseAnimation() {
		this.$el.style.display = "block";
		this.timeline = gsap.timeline();
		this.timeline.to(this.$lines, {
			x: 0,
			scaleY:1.01,
			ease: "power1.inOut",
			stagger: {
				from: "start",
				amount: 0.2,
			},
		});
		this.timeline.eventCallback("onComplete", this.navigatePage.bind(this));
	}
	handlePageChange(e) {
		const link = e.currentTarget;
		if (link.getAttribute("target") != "_blank" && !link.hash && !link.href.endsWith('#') && !link.href.startsWith('mailto') && !link.hasAttribute('download')) {
			this.nextPage = link.href;
			e.preventDefault();
			this.runCloseAnimation();
		}
	}
	handlePageShow() {
		this.runOpenAnimation();
	}
	navigatePage() {
		window.location.href = this.nextPage
	}
	removeLoader() {
		this.app.emit("enable-scroll");
		this.app.emit("loader-removed");
		this.$el.style.display = "none";
	}
	destroy() {}
}
