import EventEmitter from "eventemitter3";
import SiteMenu from "../components/site-menu";
import { addEventListener } from "../utils/event-utils";
import { getPageWidth, hasProperty } from "../utils/js-utils";
import { addClass, css, hasClass, removeClass } from "../utils/css-utils";
import { debounce } from "lodash";
import MobileDetect from "mobile-detect";
import components from "../components/_map";
import VideoPopin from "../components/video-popin";
import PageLoader from "../components/page-loader";
import HirondelleButton from "../components/hirondelle-button";
import SiteFooter from "../components/site-footer";
import NewsletterPopin from "../components/newsletter-popin";
import AnimationController from "./animation-controller";
import controllers from "./_map";
import SharePopin from "../components/share-popin";
export default class AppController extends EventEmitter {
	constructor() {
		super();

		// Handling singleton creation
		if (!AppController.singleton) {
			// Storing the singleton instance
			AppController.singleton = this;
			this.components = [];
			this.controllers = controllers;
			this.currentController = null;
			this.components_map = components;
			// Returning the singleton
			this.init();
			return AppController.singleton;
		}
	}
	init() {
		this.initAppEvents();
		this.initDomElements();
		this.initDeviceOrientationDetection();
		this.instantiateComponents();

		this.initViewportVhVar();
		this.instantiateController(
			this.$body.getAttribute("data-controller")
		);

		this.$menu = new SiteMenu();
		this.$footer = new SiteFooter();
		this.$loader = new PageLoader();
		this.$video_popin = new VideoPopin();
		this.$newsletter_popin = new NewsletterPopin();
		this.$share_popin = new SharePopin();
		this.$hirondelle_button = new HirondelleButton();
		this.$animation_controller = new AnimationController();

	}
	initDomElements() {
		this.$html = document.getElementsByTagName("html")[0];
		this.$body = document.getElementsByTagName("body")[0];
		this.$main = document.getElementsByTagName("main")[0];
		this.$loader = document.querySelector(".c-page-transition");
	}
	initAppEvents() {
		addEventListener(
			window,
			"resize",
			debounce((event) => {
				this.emit("resize", event);
			}, 16)
		);
		addEventListener(window, "scroll", (event) => {
			this.emit("scroll", event);
		});
		window.onhashchange = () => {
			this.emit("hash-change", window.location.href);
		};
		this.on("disable-scroll", () => {
			document.body.classList.add("disable-scroll");
		});
		this.on("enable-scroll", () => {
			document.body.classList.remove("disable-scroll");
		});
	}
	instantiateComponents() {
		const components = this.$body.querySelectorAll("[data-component]");
		for (let i = 0; i < components.length; i++) {
			const component = components[i];
			const name = component.getAttribute("data-component");
			if (hasProperty(this.components_map, name)) {
				const componentClass = this.components_map[name];
				if (componentClass) {
					this.components.push(new componentClass.default(component));
				}
			}
		}
	}
	instantiateController(namespace) {
		const controller = this.controllers[namespace]
		if (controller) {
			this.currentController =  new controller.default();
		}
	}

    initViewportVhVar() {
        this.updateViewportVhVar();
        this.addListener( 'resize', this.updateViewportVhVar );
    }

    updateViewportVhVar() {
        let vh = window.innerHeight;
        document.documentElement.style.setProperty( '--vh', `${ vh }px` );
    }

	initDeviceOrientationDetection() {
		// Device and orientation detection fields
		this.device = "mobile";
		this.isMobile = true;
		this.isTablet = false;
		this.isDesktop = false;
		this.orientation = "landscape";
		this.isLandscape = true;
		this.isPortrait = false;
		this.breakpoints = {
			xs: 0,
			sm: 576,
			md: 768,
			lg: 1024,
			xl: 1280,
			xxl: 1600,
		};
		this.breakpoint = "xs";

		this.detectDeviceOrientationAndBreakpoint();
		this.addListener(
			"resize",
			this.detectDeviceOrientationAndBreakpoint.bind(this)
		);
	}
	detectDeviceOrientationAndBreakpoint() {
		this.detectDevice();
		this.detectOrientation();
		this.detectBreakpoint();
	}

	detectDevice() {
		removeClass(this.$html, "mobile");
		removeClass(this.$html, "tablet");
		removeClass(this.$html, "desktop");
		const md = new MobileDetect(window.navigator.userAgent);
		this.device = md.tablet() ? "tablet" : md.mobile() ? "mobile" : "desktop";
		this.isMobile = this.device == "mobile";
		this.isTablet = this.device == "tablet";
		this.isDesktop = this.device == "desktop";
		// this.isSafari = ( md.match( 'Safari' ) && !md.match( 'Chrome' ) && md.ua.indexOf( 'CriOS' ) == -1 ); // to avoid chrome IOS to be safari detected
		this.isSafari = md.match("Safari") && !md.match("Chrome");
		this.isEdge = md.match("Edge") && md.match("Safari") && md.match("Chrome");
		this.isIE = md.match("MSIE") || md.match("Trident");
		this.isFirefox = md.match("Firefox");
		addClass(this.$html, this.device);
		if (this.isEdge) addClass(this.$html, "edge");
		if (this.isSafari) addClass(this.$html, "safari");
		if (this.isFirefox) addClass(this.$html, "firefox");
		if (this.isIE) addClass(this.$html, "ie");
	}
	detectOrientation() {
		removeClass(this.$html, "landscape");
		removeClass(this.$html, "portrait");
		this.orientation =
			window.innerWidth > window.innerHeight ? "landscape" : "portrait";
		this.isLandscape = this.orientation == "landscape";
		this.isPortrait = this.orientation == "portrait";
		addClass(this.$html, this.orientation);
	}
	detectBreakpoint() {
		const previousBreakpoint = this.breakpoint;
		const newBreakpoint = this.getCurrentBreakpoint();
		if (newBreakpoint !== previousBreakpoint) {
			this.breakpoint = newBreakpoint;
			this.emit("breakpoint-update", newBreakpoint, previousBreakpoint);
		}
	}
	getCurrentBreakpoint() {
		const pageWidth = getPageWidth();
		let previousBreakpoint = null;
		for (const breakpoint in this.breakpoints) {
			if (pageWidth < this.breakpoints[breakpoint]) {
				break;
			}
			previousBreakpoint = breakpoint;
		}
		return previousBreakpoint;
	}
	isBreakpointDown(breakpoint) {
		const max = this.getBreakpointMax(breakpoint);
		if (max) {
			return getPageWidth() < max;
		}
		return false;
	}

	getBreakpointMax(breakpoint) {
		const nextBreakpoint = this.getBreakpointNext(breakpoint);
		if (nextBreakpoint != null) {
			if (hasProperty(this.breakpoints, nextBreakpoint)) {
				return this.breakpoints[nextBreakpoint] - 0.02;
			}
		} else if (hasProperty(this.breakpoints, breakpoint)) {
			return Infinity;
		}
		return null;
	}

	getBreakpointNext(breakpoint) {
		let breakpointFound = false;
		for (let currentBreakpoint in this.breakpoints) {
			if (breakpointFound) {
				return currentBreakpoint;
			}
			if (currentBreakpoint == breakpoint) {
				breakpointFound = true;
			}
		}
		return null;
	}

	isBreakpointUp(breakpoint) {
		const min = this.getBreakpointMin(breakpoint);
		if (min != null) {
			return getPageWidth() >= min;
		}
		return false;
	}
	getBreakpointMin(breakpoint) {
		if (hasProperty(this.breakpoints, breakpoint)) {
			return this.breakpoints[breakpoint];
		}
		return null;
	}

	static getInstance() {
		return AppController.singleton;
	}
}
