import Flickity from "flickity";
import Lottie from "lottie-web";
import AbstractComponent from "../abstract-component";

export default class KeyNumbers extends AbstractComponent {
	constructor(...args) {
		super(...args);
		this.classname = "c-master-key-numbers";
		this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
		this.initLottie();
		// Mobile
		if (window.matchMedia("(max-width: 768px)").matches) {
			this.initSlider();
			// Desktop
		} else {
			this.destroySlider();
		}
	}
	initDomElements() {
		this.$container = this.$el.querySelector(
			"." + this.classname + "__content"
		);
		this.$numbers = this.$el.querySelectorAll(
			"." + this.classname + "__content__item"
		);
	}
	addEventListeners() {
		this.app.on("breakpoint-update", this.breakpointUpdateListener.bind(this));
	}
	initLottie() {
		this.$numbers.forEach((number) => {
			const player = number.querySelector(
				"." + this.classname + "__content__item__json"
			);
			if (player) {
				const json = player.querySelector("script");
				if (json.textContent && json.textContent.length > 20) {
					const lottie = Lottie.loadAnimation({
						container: player,
						renderer: "svg",
						loop: true,
						autoplay: true,
						animationData: JSON.parse(json.textContent),
					});
					lottie.play();
				}
			}
		});
	}
	breakpointUpdateListener(newBreakpoint, previousBreakpoint) {
		// Mobile
		if (window.matchMedia("(max-width: 768px)").matches) {
			this.initSlider();
			// Desktop
		} else {
			this.destroySlider();
		}
	}
	getSliderConfiguration() {
		const sliderConfiguration = {
			cellAlign: "center",
			contain: true,
			groupCells: true,
			wrapAround: true,
			pageDots: true,
			prevNextButtons: false,
		};
		return sliderConfiguration;
	}
	isInBreakpoints(breakpoint, breakpoints) {
		return breakpoints.indexOf(breakpoint) !== -1;
	}
	initSlider() {
		if (!this.slider) {
			this.slider = new Flickity(
				this.$container,
				this.getSliderConfiguration()
			);
		}
	}
	destroySlider() {
		if (this.slider) {
			this.slider.destroy();
			this.slider = null;
		}
	}
	destroy() {}
}
