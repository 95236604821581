import gsap from "gsap";
import {
	addClass,
	css,
	hasClass,
	removeClass,
} from "../../utils/css-utils";
import { addEventListener } from "../../utils/event-utils";
import { MANAGE_QUIZ_EVENT, MANAGE_QUESTION_EVENT } from "./quiz";

export default class QuestionManager {
	constructor({ el, quiz }) {
		this.$el = el;
		this.timeline = null;
		this.quiz = quiz;
		this.question = this.quiz.getCurrentQuestion();
		this.answers_indexes = ["answer-A", "answer-B", "answer-C", "answer-D"];
		this.initDomElements();
		this.setCurrentQuestionData();

		this.initTimeline();
		this.addEventListeners();
	}

	initDomElements() {
		this.$header = this.$el.querySelector(".js-question__header");
		this.$title = this.$el.querySelector(".js-question__header__title");
		this.$progress = this.$el.querySelector(
			".js-question__header__surtitle-progress"
		);
		this.$right = this.$el.querySelector(".js-question__content__right");
		this.$left = this.$el.querySelector(".js-question__content__left");
		this.$image = this.$el.querySelector(".js-question__content__image");
		this.$image_clues = this.$el.querySelectorAll(
			".js-question__content__clue"
		);
		this.$answers = this.$el.querySelectorAll(".js-question__content__answer");
		this.$validate_button = this.$right.querySelector(".c-button");
	}
	initTimeline() {
		this.timeline = gsap.timeline({ paused: true });
		this.timeline.to(
			this.$header,
			{
				autoAlpha: 0,
				duration: 0.5,
				y: -10,
			},
			0
		);
		this.timeline.to(
			this.$left,
			{
				autoAlpha: 0,
				duration: 0.5,
				x: -10,
			},
			0
		);
		this.timeline.to(
			this.$right,
			{
				autoAlpha: 0,
				duration: 0.5,
				x: 10,
			},
			0
		);
		this.timeline.eventCallback("onComplete", () => {
			this.incrementeIndex();
			this.changeButtonState();
			this.setCurrentQuestionData();
			this.enableAnswers();
			setTimeout(() => {
				this.timeline.reverse(0);
			}, 500);
		});
	}
	addEventListeners() {
		addEventListener(this.$answers, "click", this.handleChoice.bind(this));
		this.quiz.controller.on(
			MANAGE_QUESTION_EVENT.NEXT_QUESTION,
			this.nextQuestion.bind(this)
		);
		this.quiz.controller.on(
			MANAGE_QUIZ_EVENT.QUIZ_END,
			this.hideQuiz.bind(this)
		);
		this.quiz.controller.on(
			MANAGE_QUESTION_EVENT.VALIDATE_ANSWER,
			this.disableAnswers.bind(this)
		);

		this.$validate_button.addEventListener("click", () => {
			const state = this.$validate_button.getAttribute("data-state");

			if (state == "validate") {
				this.validateChoice();
			} else if (state == "next") {
				this.quiz.controller.emit(MANAGE_QUESTION_EVENT.NEXT_QUESTION);
			}
		});
	}
	showQuiz() {
		removeClass(this.$el, "disabled");
		this.timeline.reverse(0);
	}
	hideQuiz() {
		css(this.$el, { display: "none" });
	}
	disableAnswers() {
		css(this.$answers, { "pointer-events": "none" });
	}
	enableAnswers() {
		css(this.$answers, { "pointer-events": "all" });
	}
	nextQuestion() {
		if (this.quiz.current_index < this.quiz.questions.length - 1) {
			this.timeline.play();
		} else {
			this.quiz.controller.emit(MANAGE_QUESTION_EVENT.LAST_QUESTION);
		}
	}
	incrementeIndex() {
		this.quiz.current_index++;
		this.question = this.quiz.getCurrentQuestion();
	}
	setCurrentQuestionData() {
		this.question.answered = [];
		this.$title.innerText = this.question.title;
		this.$progress.innerText = `Question ${this.quiz.current_index + 1} / ${
			this.quiz.questions.length
		}`;
		this.$image.src = this.question.image;
		this.$image_clues.forEach(this.setClues.bind(this));
		this.setAnswers();
	}
	setClues(clue, i) {
		clue.innerText = this.question.clues[i].clue;
	}
	setAnswers() {
		this.$answers.forEach((answer, i) => {
			removeClass(answer, "true");
			removeClass(answer, "false");
			removeClass(answer, "selected");
			if (this.question.answers[i].title) {
				answer.querySelector(".js-answer__label").innerText =
					this.question.answers[i].title;
			} else {
				css(answer, { display: "none" });
			}
		});
	}
	setAnswersColors() {
		this.question.answered.forEach((answer) => {
			if (this.question.answers[answer].is_right) {
				addClass(this.$answers[answer], "true");
			} else {
				addClass(this.$answers[answer], "false");
			}
		});
		this.$answers.forEach((answer, i) => {
			if (this.question.answers[i].is_right) {
				addClass(answer, "true");
			}
		});
	}
	validateChoice() {
		if (this.question.answered) {
			this.setAnswersColors();
			this.changeButtonState();
			this.quiz.controller.emit(MANAGE_QUESTION_EVENT.VALIDATE_ANSWER);
		}
	}
	handleChoice(e) {
		const is_selected = hasClass(e.currentTarget, "selected");
		const answered = this.answers_indexes.indexOf(e.currentTarget.id);
		if (is_selected) {
			this.question.answered.splice(
				this.question.answered.indexOf(answered),
				1
			);
			removeClass(e.currentTarget, "selected");
		} else {
			this.question.answered.push(answered);
			addClass(e.currentTarget, "selected");
		}
	}
	changeButtonState() {
		if (this.$validate_button.getAttribute("data-state") == "validate") {
			this.$validate_button.setAttribute("data-state", "next");
			this.$validate_button.innerText = "Suivant";
		} else {
			this.$validate_button.setAttribute("data-state", "validate");
			this.$validate_button.innerText = "Valider";
		}
	}
}
