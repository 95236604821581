import Quiz, { MANAGE_QUESTION_EVENT } from "../components/quiz/quiz";
import Request from "../utils/request";
import AppController from "./app-controller";
import { MANAGE_QUIZ_EVENT } from "../components/quiz/quiz";
import { EventEmitter } from "eventemitter3";

export default class QuizController extends EventEmitter {
	constructor() {
		super();
		this.action = "hb_quiz_ajax";
		this.data = null;
		this.request = null;
		this.params = {
			quiz_id: null,
		};
		this.app = AppController.getInstance();
		this.device =
			this.app.breakpoints[this.app.breakpoint] < this.app.breakpoints["lg"]
				? "mobile"
				: "desktop";
		this.init();
	}
	init() {
		this.quiz = new Quiz({ device: this.device, controller: this });
		this.getQuiz();
	}
	getQuiz() {
		this.quiz_id = window.HB.quiz_id;
		this.request = Request.AJAX({
			url: window.HB.ajax_url + "?action=" + this.action,
			data: { quiz_id: this.quiz_id },
			success: this.instanciateQuiz.bind(this),
		});
	}
	instanciateQuiz(response) {
		try {
			this.emit(MANAGE_QUIZ_EVENT.QUIZ_START, response.data);
		} catch (error) {
			console.log(error);
		}
	}
	destroy() {}
}
