import AbstractComponent from "./abstract-component";
import gsap from "gsap";
import Request from '../utils/request';

export default class NewsletterPopin extends AbstractComponent {
	constructor(el) {
		super(el);
		this.classname = "c-newsletter-popin";
		this.action = "hb_newsletter_ajax";
		this.data = null;
		this.request = null;
		this.params = {
			departement: null,
			email: null,
		};

		this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
		this.$form = document.querySelector(".js-" + this.classname + "__form");
		this.$form_rows = document.querySelectorAll(".js-" + this.classname + "__form__row");
		this.$cross = document.querySelector(".js-" + this.classname + "__cross");
		this.$loader = document.querySelector(".js-" + this.classname + "__spinner");
		this.$SuccessMessage = document.querySelector(".js-" + this.classname + "__success-message");
	}
	addEventListeners() {
		this.handleHashChange();
		this.app.on("hash-change", this.handleHashChange.bind(this));
		this.$cross.addEventListener("click", this.runCloseAnimation.bind(this));
		this.$form.addEventListener("submit", this.submitNewsletterForm.bind(this));
	}
	runOpenAnimation() {
		this.timeline = gsap.timeline();
		this.timeline.to(this.$el, {
			pointerEvents: "all",
			ease: "power1.inOut",
			duration: 0,
		});
		this.timeline.to(this.$el, {
			opacity: 1,
			ease: "power1.inOut",
			duration: 0.2,
		});
		this.timeline.to(this.$form, {
			x: 0,
			ease: "power1.inOut",
			duration: 0.5,
		});
		this.timeline.to(this.$cross, {
			opacity: 1,
			pointerEvents: "all",
			ease: "power1.inOut",
			duration: 0.3,
		});
		this.timeline.eventCallback("onComplete", () =>
			this.app.emit("disable-scroll")
		);
	}
	runCloseAnimation() {
		this.timeline = gsap.timeline();
		this.timeline.to(this.$cross, {
			opacity: 0,
			pointerEvents: "none",
			ease: "power1.inOut",
			duration: 0.2,
		});
		this.timeline.to(this.$form, {
			x: "100%",
			ease: "power1.inOut",
			duration: 0.5,
		});
		this.timeline.to(this.$el, {
			opacity: 0,
			ease: "power1.inOut",
			duration: 0.2,
		});
		this.timeline.to(this.$el, {
			opacity: 0,
			pointerEvents: "none",
			ease: "power1.inOut",
			duration: 0,
		});
		this.timeline.eventCallback("onComplete", this.removeHash.bind(this));
	}
	handleHashChange() {
		if (window.location.hash == "#newsletter") {
			this.app.once("loader-removed", () => this.app.emit("disable-scroll"));
			this.runOpenAnimation();
		}
	}
	removeHash() {
		this.app.emit("enable-scroll");
		window.history.replaceState(
			"",
			document.title,
			window.location.href.replace("#newsletter", "")
		);
	}
	submitNewsletterForm(e) {
		e.preventDefault();
		this.params.departement = this.$form.departement.value;
		this.params.email = this.$form.email.value;
		this.showLoader();
        this.request = Request.AJAX({
            'url': window.HB.ajax_url + "?action=" + this.action,
            'data': this.params,
            'success': this.onSuccess.bind( this )
        } );
	}
	onSuccess(response) {
		this.hideLoader();
	}
	hideForm(){
		this.$form_rows.forEach(row=>{
			row.style.display = "none"
		})
	}
	hideLoader(){
		this.$loader.style.display = "none"
		this.showSuccesMessage();
	}
	showLoader(){
		this.$loader.style.display = "flex"
		this.hideForm();
	}
	showSuccesMessage(){
		this.$SuccessMessage.style.display = "block" 
	}
	destroy() {}
}
