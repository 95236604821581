import AbstractComponent from "../abstract-component";
import { addClass, css, removeClass } from "../../utils/css-utils";
import { addEventListener } from "../../utils/event-utils";
import { hasProperty } from "../../utils/js-utils";
import Request from "../../utils/request";
import mapboxgl from "mapbox-gl";
import MapboxglSpiderifier from "mapboxgl-spiderifier";
import ZoomControl from "mapbox-gl-controls/lib/zoom";
import gsap from "gsap";
import _ from "lodash";
export default class Map extends AbstractComponent {
	constructor(...args) {
		super(...args);
		this.classname = "c-map";
		this.action = "hb_censuses_ajax";
		this.mapMaxZoom = 17;
		this.mapSpiderifyZoom = 9;
		this.zoom = 4.2;
		this.initDomElements();
		this.initMapboxMap();
		this.addEventListeners();
	}

	initDomElements() {
		this.$el = document.querySelector("." + this.classname);
		this.$mapContainer = this.$el.querySelector(
			"." + this.classname + "__map-container"
		);
		this.$mapLoader = this.$el.querySelector("." + this.classname + "__loader");
		this.$filterSelect = this.$el.querySelector(
			"." + this.classname + "__year-filter"
		);
		this.$counter_rustic = this.$el.querySelector(
			"." + this.classname + "__counter.rustic span"
		);
		this.$counter_window = this.$el.querySelector(
			"." + this.classname + "__counter.window span"
		);
		this.$shadow = this.$el.querySelector("." + this.classname + "__shadow");
	}

	initMapboxMap() {
		this.actions = [];
		this.filteredActions = [];
		this.actionsPopinData = {};
		if (this.$mapContainer) {
			const activeLocation = this.getActiveLocationCenterAndZoom();

			// Creating the Mapbox map
			mapboxgl.accessToken = window.HB.mapbox_access_token;
			this.mapboxMap = new mapboxgl.Map({
				container: this.$mapContainer,
				style: "mapbox://styles/chasseurdefrance/clefz4rjr001z01nt4o0bc3gn",
				center: activeLocation.center,
				zoom: activeLocation.zoom,
				maxZoom: this.mapMaxZoom,
				attributionControl: false,
				cooperativeGestures: true,
				scrollZoom: false,
				locale: {
					"TouchPanBlocker.Message":
						"Utilisez deux doigts pour déplacer la carte",
				},
			});

			// Adding attribution and zoom controls
			this.mapboxMap.addControl(
				new mapboxgl.AttributionControl({ compact: true })
			);
			this.mapboxMap.addControl(new ZoomControl(), "bottom-left");

			// Creating a popup, but don't add it to the map yet
			this.mapboxMapPopupHoverActionId = null;
			this.mapboxMapPopup = new mapboxgl.Popup({
				closeOnClick: false,
				offset: {
					offset: [20, 20],
				},
				maxWidth: this.app.device == "mobile" ? "90vw" : "430px",
				anchor: this.app.device == "mobile" ? "center" : null,
			});
		}
	}

	addEventListeners() {
		addEventListener(
			this.$filterSelect,
			"change",
			this.filterHirondelles.bind(this)
		);
		this.addMapboxMapEventListeners();
	}

	addMapboxMapEventListeners() {
		if (this.$mapContainer && this.mapboxMap) {
			this.mapboxMap.on("load", () => {
				// Source
				this.mapboxMap.addSource("actions", {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: [],
					},
					cluster: true,
					clusterRadius: 35,
				});

				// Clusters circles layout
				this.mapboxMap.addLayer({
					id: "clusters",
					type: "circle",
					source: "actions",
					filter: ["has", "point_count"],
					paint: {
						"circle-color": "#86A62C",
						"circle-radius": [
							"case",
							["boolean", ["feature-state", "spiderifyHover"], false],
							5,
							21,
						],
						"circle-opacity": [
							"case",
							["boolean", ["feature-state", "spiderifyHover"], false],
							0,
							1,
						],
					},
				});

				// Clusters counts layout
				this.mapboxMap.addLayer({
					id: "cluster-count",
					type: "symbol",
					source: "actions",
					filter: ["has", "point_count"],
					layout: {
						"text-field": "{point_count}",
						"text-size": 16,
					},
					paint: {
						"text-color": "#FFFFFF",
						"text-opacity": [
							"case",
							["boolean", ["feature-state", "spiderifyHover"], false],
							0,
							1,
						],
					},
				});

				// Actions circles
				this.mapboxMap.addLayer({
					id: "unclustered-point",
					type: "circle",
					source: "actions",
					filter: ["!", ["has", "point_count"]],
					paint: {
						"circle-color": ["get", "color"],
						"circle-radius": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							16,
							8,
						],
						"circle-stroke-width": [
							"case",
							["boolean", ["feature-state", "hover"], false],
							4,
							3,
						],
						"circle-stroke-color": "#ffffff",
					},
				});
				this.mapboxMapPopup.on("close", this.hideShadow.bind(this));
				// Initializing the map data
				this.initMapboxMapData();

				// Initializing map spiderifier and undo events
				this.mapboxMapSpiderifier = this.getMapboxMapSpiderifier();
				this.mapboxMap.on("zoomstart", () => this.unspiderifyMapboxMap());
				this.mapboxMap.on("dragstart", () => this.unspiderifyMapboxMap());

				// Inspect a cluster on click or spiderify the cluster (if on max zoom)
				this.mapboxMap.on("click", (e) => {
					const clustersFeatures = this.mapboxMap.queryRenderedFeatures(
						e.point,
						{ layers: ["clusters"] }
					);
					const clusterCountfeatures = this.mapboxMap.queryRenderedFeatures(
						e.point,
						{ layers: ["cluster-count"] }
					);
					const clusterFeature =
						clustersFeatures.length > 0 ? clustersFeatures[0] : null;
					const clusterCountfeature =
						clusterCountfeatures.length > 0 ? clusterCountfeatures[0] : null;

					if (clusterFeature && !this.mapboxMapSpiderifierCluster) {
						if (this.mapboxMap.getZoom() < this.mapSpiderifyZoom) {
							this.mapboxMap
								.getSource("actions")
								.getClusterExpansionZoom(
									clusterFeature.properties.cluster_id,
									(err, zoom) => {
										if (err) {
											return;
										}
										this.mapboxMap.easeTo({
											center: clusterFeature.geometry.coordinates,
											zoom: zoom,
										});
									}
								);
						} else {
							this.mapboxMapSpiderifierCluster = clusterFeature;
							this.setMapboxMapSpiderifyHoverActionState(
								this.mapboxMapSpiderifierCluster,
								true
							);
							this.mapboxMapSpiderifierClusterCount = clusterCountfeature;
							this.setMapboxMapSpiderifyHoverActionState(
								this.mapboxMapSpiderifierClusterCount,
								true
							);
							this.mapboxMap
								.getSource("actions")
								.getClusterLeaves(
									clusterFeature.properties.cluster_id,
									150,
									0,
									(err, leafFeatures) => {
										if (err) {
											return;
										}
										this.mapboxMapSpiderifier.spiderfy(
											clusterFeature.geometry.coordinates,
											leafFeatures
										);
									}
								);
						}
					} else {
						// this.unspiderifyMapboxMap();
					}
				});

				// 'Click' listener on actions
				this.mapboxMap.on("click", "unclustered-point", (e) => {
					if (e.features.length > 0) {
						const action = e.features[0];
						this.actionPopinFromHistory = false;
						this.mapboxMapPopup.remove();
						this.hideShadow();
						this.showActionPopinForAction(action.id);
					}
				});

				// 'Mouse Enter' & 'Mouse Leave' listeners on clusters layers
				const addCursor = () =>
					(this.mapboxMap.getCanvas().style.cursor = "pointer");
				const removeCursor = () =>
					(this.mapboxMap.getCanvas().style.cursor = "");
				this.mapboxMap.on("mouseenter", "clusters", addCursor);
				this.mapboxMap.on("mouseleave", "clusters", removeCursor);
				this.mapboxMap.on("mouseenter", "unclustered-point", addCursor);
				this.mapboxMap.on("mouseleave", "unclustered-point", removeCursor);
			});
		}
	}

	getMapboxMapSpiderifier() {
		this.mapboxMapSpiderifierCluster = null;
		this.mapboxMapSpiderifierClusterCount = null;
		this.mapboxMapSpiderifierPopup = null;
		return new MapboxglSpiderifier(this.mapboxMap, {
			animate: true,
			animationSpeed: 250,
			customPin: true,
			onClick: (e, spiderLeg) => {
				const action = spiderLeg.feature;
				this.actionPopinFromHistory = false;
				this.mapboxMapPopup.remove();
				this.showActionPopinForAction(action.id);
			},
			initializeLeg: (spiderLeg) => {
				const pin = spiderLeg.elements.pin;
				const action = spiderLeg.feature;

				let backgroundColor = "#61615a";
				backgroundColor = action.properties.color;
				css(pin, { backgroundColor: backgroundColor });
			},
		});
	}

	unspiderifyMapboxMap() {
		this.mapboxMapSpiderifier.unspiderfy();
		this.setMapboxMapSpiderifyHoverActionState(
			this.mapboxMapSpiderifierCluster,
			false
		);
		this.setMapboxMapSpiderifyHoverActionState(
			this.mapboxMapSpiderifierClusterCount,
			false
		);
		setTimeout(() => {
			this.mapboxMapSpiderifierCluster = null;
			this.mapboxMapSpiderifierClusterCount = null;
		}, 100);
		this.mapboxMapPopup.remove();
	}

	setMapboxMapSpiderifyHoverActionState(item, state) {
		if (item) {
			this.mapboxMap.setFeatureState(item, { spiderifyHover: state });
		}
	}

	getMapboxMapProgramsColors() {
		const colorsSpecs = ["case"];
		for (const program of window.FNC.actions_map_programs) {
			if (hasProperty(program, "id") && hasProperty(program, "color")) {
				colorsSpecs.push(["in", program.id, ["get", "t"]]);
				colorsSpecs.push(program.color);
			}
		}
		colorsSpecs.push("#61615a");
		return colorsSpecs;
	}

	initMapboxMapData() {
		Request.AJAX({
			url: window.HB.ajax_url + "?action=" + this.action,
			success: (response) => {
				if (response && hasProperty(response, "data")) {
					this.actions = response.data;
					this.filteredActions = this.actions;
					this.updateMapboxMapActions();
					this.setHirondelleCount();
					this.removeLoader();
				}
			},
		});
	}
	updateMapboxMapActions() {
		this.mapboxMap.getSource("actions").setData({
			type: "FeatureCollection",
			features: this.filteredActions,
		});
	}
	getActiveLocationCenterAndZoom() {
		return {
			center: [2.348016, 48.85514],
			zoom: this.zoom,
		};
	}
	filterHirondelles() {
		const value = this.$filterSelect.value;
		if (value) {
			this.filteredActions = this.actions.filter((hirondelle) => {
				return value == hirondelle.properties.year;
			});
		} else {
			this.filteredActions = this.actions;
		}
		this.unspiderifyMapboxMap();

		this.updateMapboxMapActions();
	}
	setHirondelleCount() {
		const hirondell_rustic = [];
		const hirondell_window = [];
		this.actions.forEach((hirondelle) => {
			if (hirondelle.properties.type.value == "rustic") {
				hirondell_rustic.push(hirondelle);
			} else {
				hirondell_window.push(hirondelle);
			}
		});
		this.$counter_rustic.innerText = hirondell_rustic.length;
		this.$counter_window.innerText = hirondell_window.length;
	}
	removeLoader() {
		gsap.to(this.$mapLoader, {
			autoAlpha: 0,
			pointerEvents: "none",
			scale: 1.2,
			duration: 0.5,
		});
	}
	showActionPopinForAction(hirondelle_id) {
		this.current = _.find(this.filteredActions, { id: hirondelle_id });
		const coordinates =
			this.app.device == "mobile"
				? this.mapboxMap.getCenter()
				: this.current.geometry.coordinates;
		this.mapboxMapPopup
			.setLngLat(coordinates)
			.setHTML(this.getTemplate(this.current))
			.addTo(this.mapboxMap);
		this.setShadow();
	}
	getTemplate(hirondelle) {
		let template = "";
		if (hirondelle.properties.type) {
			template += `<div class="popin-hirondelle-type ${hirondelle.properties.type.value}">${hirondelle.properties.type.label}</div>`;
		}
		if (hirondelle.properties.pseudo) {
			template += `<div class="popin-hirondelle-info"><span>Qui ? </span>${hirondelle.properties.pseudo}</div>`;
		}
		if (hirondelle.properties.date) {
			template += `<div class="popin-hirondelle-info"><span>Quand ? </span>${hirondelle.properties.date}</div>`;
		}
		if (hirondelle.properties.building_type) {
			template += `<div class="popin-hirondelle-info"><span>Zone ?  </span>${hirondelle.properties.building_type.label}</div>`;
		}
		if (hirondelle.properties.department) {
			template += `<div class="popin-hirondelle-info"><span>Département ?  </span>${hirondelle.properties.department}</div>`;
		}
		if (hirondelle.properties.amount) {
			template += `<div class="popin-hirondelle-info"><span>Nombre d’hirondelles ? </span>${hirondelle.properties.amount}</div>`;
		}
		if (hirondelle.properties.has_nest) {
			if (hirondelle.properties.amount_in_nest) {
				template += `
				<div class="popin-hirondelle-info"><span>Nombre de nids ?  </span>${this.current.properties.amount_in_nest}</div>`;
			}
			if (hirondelle.properties.chicks_amount_in_nest) {
				template += `<div class="popin-hirondelle-info"><span>Nombre de poussins dans le nids ?  </span>${this.current.properties.chicks_amount_in_nest}</div>`;
			}
		}
		template += `<div class="popin-hirondelle-info"><span>Boue a proximité ? </span>${hirondelle.properties.has_mud ? "Oui": "Non"}</div>`;
		return template;
	}
	setShadow() {
		addClass(this.$shadow, "active");
		addEventListener(this.$shadow, "click", () => {
			this.mapboxMapPopup.remove();
			this.hideShadow();
		});
	}
	hideShadow() {
		removeClass(this.$shadow, "active");
	}
	destroy() {}
}
