import AbstractComponent from "./abstract-component";

export default class HirondelleButton extends AbstractComponent {
	constructor(el) {
		super(el);
		this.classname = "c-hirondelle-button";
		this.init();
	}
	init() {
		this.initDomElements();

		if (this.$el && this.app.$footer.$el) {
			this.handleScroll();
			this.addEventListeners();
		}
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
	}
	addEventListeners() {
		this.app.on("scroll", this.handleScroll.bind(this));
	}
	handleScroll() {
		if (
			window.scrollY + window.innerHeight >
			document.body.scrollHeight - this.app.$footer.height
		) {
			this.$el.classList.add("hidden");
		} else {
			this.$el.classList.remove("hidden");
		}
	}
	destroy() {}
}
