import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { MANAGE_CENSUS_EVENT } from "./census";
import { addEventListener } from "../../utils/event-utils";
import { MANAGE_CENSUS_MAP_EVENT } from "./census-map";
import { css } from "../../utils/css-utils";
export default class SearchBar {
	constructor($el, map) {
		this.$el = $el;
		this.map = map;
		this.result = null;
		this.initDomElements();
		this.initMapboxMap();
		this.addEventListeners();
	}
	initDomElements() {
		this.$geolocation = this.$el.querySelector(".js-localise");
		this.$spinner = this.$el.querySelector(".js-spinner");
		this.$submitButton = this.$el.querySelector(".c-button");
	}
	initMapboxMap() {
		if (this.$el) {
			this.geocoder = new MapboxGeocoder({
				accessToken: window.HB.mapbox_access_token,
				countries: "fr",
				placeholder: "Entrer une ville ou une adresse",
				limit: 3,
			});
			this.geocoder.addTo(this.$el);
			this.geocoder.on("result", (data) => {
				this.result = data.result;
				if (window.matchMedia("screen and (max-width: 1023px)").matches) {
					this.setLocation();
				}
			});
		}
	}

	addEventListeners() {
		if (!window.matchMedia("screen and (max-width: 1023px)").matches) {
			addEventListener(
				this.$submitButton,
				"click",
				this.setLocation.bind(this)
			);
		}
		addEventListener(
			this.$geolocation,
			"click",
			this.askGeolocation.bind(this)
		);
	}

	setLocation() {
		if (this.result) {
			this.map.emit(MANAGE_CENSUS_MAP_EVENT.SET_MAP_POSITION, {
				latitude: this.result.center[1],
				longitude: this.result.center[0],
			});
			this.map.emit(MANAGE_CENSUS_MAP_EVENT.SHOW_MAP);
		} else {
			this.emit(
				MANAGE_CENSUS_EVENT.SHOW_NO_VALUE_MSG,
				"Veuillez entrer une adresse ou une ville"
			);
		}
	}
	askGeolocation() {
		css(this.$spinner, { display: "flex" });
		css(this.$geolocation.parentNode, { display: "none" });
		navigator.geolocation.getCurrentPosition(
			this.geoSuccess.bind(this),
			this.geoError.bind(this)
		);
	}
	geoSuccess(data) {
		this.map.emit(MANAGE_CENSUS_MAP_EVENT.SET_MAP_POSITION, {
			latitude: data.coords.latitude,
			longitude: data.coords.longitude,
		});
		this.map.emit(MANAGE_CENSUS_MAP_EVENT.SHOW_MAP);
	}
	geoError(data) {
		console.log(data);
	}
	destroy() {}
}
