import AbstractComponent from "./abstract-component";
import gsap from "gsap";

export default class SharePopin extends AbstractComponent {
	constructor(el) {
		super(el);
		this.classname = "c-share-popin";
		this.hash = "#share"

		this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$el = document.querySelector(".js-" + this.classname);
		this.$cross = document.querySelector(".js-" + this.classname + "__cross");
	}
	addEventListeners() {
		this.handleHashChange();
		this.app.on("hash-change", this.handleHashChange.bind(this));
		this.$cross.addEventListener("click", this.runCloseAnimation.bind(this));
	}
	runOpenAnimation() {
		this.timeline = gsap.timeline();
		this.timeline.to(this.$el, {
			pointerEvents: "all",
			ease: "power1.inOut",
			duration: 0,
		});
		this.timeline.to(this.$el, {
			opacity: 1,
			ease: "power1.inOut",
			duration: 0.2,
		});
		this.timeline.eventCallback("onComplete", () =>
			this.app.emit("disable-scroll")
		);
	}
	runCloseAnimation() {
		this.timeline = gsap.timeline();
		this.timeline.to(this.$el, {
			opacity: 0,
			ease: "power1.inOut",
			duration: 0.2,
		});
		this.timeline.to(this.$el, {
			opacity: 0,
			pointerEvents: "none",
			ease: "power1.inOut",
			duration: 0,
		});
		this.timeline.eventCallback("onComplete", this.removeHash.bind(this));
	}
	handleHashChange() {
		if (window.location.hash == this.hash) {
			this.app.once("loader-removed", () => this.app.emit("disable-scroll"));
			this.runOpenAnimation();
		}
	}
	removeHash() {
		this.app.emit("enable-scroll");
		window.history.replaceState(
			"",
			document.title,
			window.location.href.replace(this.hash, "")
		);
	}
	destroy() {}
}
