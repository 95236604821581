import EventEmitter from "eventemitter3";
import { addClass, removeClass } from "../../utils/css-utils";
import { addEventListener } from "../../utils/event-utils";
import { MANAGE_CENSUS_EVENT } from "./census";

export default class Form extends EventEmitter {
	constructor($el, census) {
		super();
		this.$el = $el;
		this.census = census;
		this.false =this.false;
		(this.data = { pseudo: "", name: "", surname: "", email: "" }), this.init();
	}
	init() {
		this.initDomElements();
		this.addEventListeners();
	}
	initDomElements() {
		this.$inputs = this.$el.querySelectorAll("input");
	}
	addEventListeners() {
		addEventListener(this.$el, "submit", this.setValue.bind(this));
	}
	setValue(e) {
		e.preventDefault();
		this.false = false;
		this.$inputs.forEach((input) => {
			if (!input.value) {
				this.false = true;
				addClass(input.closest(".js-row"), "error");
			} else {
				removeClass(input.closest(".js-row"), "error");
			}
		});
		if (!this.false) {
			for (const key in this.data) {
				this.data[key] = this.$el[key].value;
			}
			this.census.emit(MANAGE_CENSUS_EVENT.SET_ANSWER_VALUE, this.data);
			this.census.emit(MANAGE_CENSUS_EVENT.NEXT_STEP);
		}
	}
}
