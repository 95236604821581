import AOS from "aos";
import { TweenMax, TimelineLite, Power2 } from "gsap/all";

export default class AnimationController {
	constructor() {
		this.timelines = [];
		this.init();
	}
	init() {
		this.initDOMElements();
		this.initTimelines();
		this.initEvents();
		this.initAOS();
	}
	initAOS() {
		AOS.init({
			duration: 1000,
			once: false,
		});
	}
	initEvents() {
		document.addEventListener("aos:in", ({ detail }) => {
			this.matchTimeline(detail);
		});
	}

	initDOMElements() {
		this.$animatedEls = document.body.querySelectorAll("[data-aos]");
	}
	matchTimeline(el) {
		if (this.$animatedEls) {
			for (var i = 0, j = this.$animatedEls.length; i < j; i++) {
				if (this.timelines[i].el == el) {
					this.timelines[i].tween.play();
				}
			}
		}
	}

	initTimelines() {
		if (this.$animatedEls) {
			for (var i = 0, j = this.$animatedEls.length; i < j; i++) {
				var data = {
					el: this.$animatedEls[i],
					tween: this.getTimeline(
						this.$animatedEls[i].getAttribute("data-aos"),
						this.$animatedEls[i]
					),
				};

				this.timelines.push(data);
			}
		}
	}
	/**
	 * Get Timeline for SCROLL animation
	 * @param {String} `animationName` animation timeline to set
	 * @param {Element} `el` element to animate
	 */
	getTimeline(animationName, el) {
		var tween = new TimelineLite({ paused: true });
		switch (animationName) {

			/////////////////////////////////////////////////
			// GENERICS
			////////////////////////////////////////////////

			// HEADER
			case "header":
				var background = el.querySelector(".c-header__hide");
				var content = el.querySelectorAll(".animated-text");
				var button = el.querySelectorAll(".c-button");
				tween.fromTo(
					background,
					{ x: 0 },
					{ x: "-100%", ease: Power2.easeOut, duration: 1 },
					"start"
				);
				tween.fromTo(
					content,
					{ autoAlpha: 0, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					}
				);
				tween.fromTo(
					button,
					{ autoAlpha: 0 },
					{
						autoAlpha: 1,
						ease: Power2.easeOut,
						duration: 0.5,
					}
				);
				break;

			// MASTER - DOUBLE COLUMNS
			case "master-two-columns":
				var background = el.querySelector(".c-master-two-columns__hide");
				var content = el.querySelectorAll(".animated-text");
				var button = el.querySelectorAll(".c-button");
				tween.fromTo(
					background,
					{ width: "100%" },
					{ width: 0, ease: Power2.easeOut, duration: 1 },
					"start"
				);
				tween.fromTo(
					content,
					{ autoAlpha: 0, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					},
					0.1,
					"start+=0.7"
				);
				tween.fromTo(
					button,
					{ autoAlpha: 0 },
					{
						autoAlpha: 1,
						ease: Power2.easeOut,
						duration: 0.5,
					}
				);
				break;

			// SINGLE HIRONDELLE
			case "single-hirondelle":
				var background = el.querySelector(".c-single-hirondelle__hide");
				var content = el.querySelectorAll(".animated-text");
				var button = el.querySelectorAll(".c-button");
				tween.fromTo(
					background,
					{ width: "100%" },
					{ width: 0, ease: Power2.easeOut, duration: 1 },
					"start"
				);
				tween.fromTo(
					content,
					{ autoAlpha: 0, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					},
					0.1,
					"start+=0.7"
				);
				tween.fromTo(
					button,
					{ autoAlpha: 0 },
					{
						autoAlpha: 1,
						ease: Power2.easeOut,
						duration: 0.5,
					}
				);
				break;


			// MASTER - ONE COLUMN
			case "master-one-column":
				var content = el.querySelectorAll(".animated-text");
				var button = el.querySelectorAll(".c-button");
				tween.fromTo(
					content,
					{ autoAlpha: 0, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					},
					0.1,
					"start+=0.7"
				);
				tween.fromTo(
					button,
					{ autoAlpha: 0 },
					{
						autoAlpha: 1,
						ease: Power2.easeOut,
						duration: 0.5,
					}
				);
				break;

			// MASTER - PUSH HIRONDELLES
			case "master-push-hirondelles":
				var content = el.querySelectorAll(".animated-text");
				var button = el.querySelectorAll(".c-button");
				tween.fromTo(
					content,
					{ autoAlpha: 0, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					}
				);
				tween.fromTo(
					button,
					{ autoAlpha: 0 },
					{
						autoAlpha: 1,
						ease: Power2.easeOut,
						duration: 0.5,
					}
				);
				break;

			// MASTER - PUSH NEWSLETTER
			case "master-newsletter":
				var content = el.querySelectorAll(".animated-text");
				tween.fromTo(
					content,
					{ autoAlpha: 0, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					}
				);
				break;

			// MASTER - COORDINATES
			case "master-coordinates":
				var content = el.querySelectorAll(".animated-text");
				tween.fromTo(
					content,
					{ autoAlpha: 0, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					}
				);
				break;

			// MASTER - KEY NUMBERS
			case "master-key-numbers":
				var content = el.querySelectorAll(".animated-text");
				tween.fromTo(
					content,
					{ autoAlpha: 0 },
					{
						autoAlpha: 1,
						ease: Power2.easeOut,
						duration: 0.5,
						stagger: { each: 0.25 },
					}
				);
				break;

			// MASTER - WYSIWYG
			case "master-wysiwyg":
				var content = el.querySelectorAll(".animated-text");
				tween.fromTo(
					content,
					{ autoAlpha: 0.2, y: 25 },
					{
						autoAlpha: 1,
						y: 0,
						ease: Power2.easeOut,
						duration: 0.65,
						stagger: { each: 0.25 },
					}
				);
				break;
		}

		return tween;
	}
}
